
		import Async from "../../node_modules/@preact/async-loader/async.js";

		function load(cb) {
			require.ensure([], function (require) {
				var result = require("!!../../node_modules/babel-loader/lib/index.js??ref--4!./tryout_register_partner.js");
				typeof cb === 'function' && cb(result);
			}, "route-tryout_register_partner");
		}

		export default Async(load);
	