import { h, Component } from 'preact';
import { Router } from 'preact-router';

// Google Analytics
import ReactGA from 'react-ga';
ReactGA.initialize('UA-148212892-3');

// Facebook Pixel
import ReactPixel from 'react-facebook-pixel';

const advancedMatching = { 
	// em: 'some@email.com' 
}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: true, // enable logs
};
ReactPixel.init('316722092385296', advancedMatching, options);

// Code-splitting is automated for routes
import Home from '../routes/home';
import Promo from '../routes/promo';
import Promo_detail from '../routes/promo_detail';
import Promo_detail_to10 from '../routes/promo_detail_to10';
import Promo_detail_register from '../routes/promo_detail_register';
import Promo_detail_register_success from '../routes/promo_detail_register_success';

import Pman from '../routes/pman';
import Pman_detil from '../routes/pman_detil';

import Program_intensif from '../routes/program_intensif';

import Careers from '../routes/careers';

import Olle_vip from '../routes/olle_vip';
import Olle_vip_alumni from '../routes/olle_vip_alumni';
import Olle_vip_alumni_soshum from '../routes/olle_vip_alumni_soshum';
import Olle_vip_register from '../routes/olle_vip_register';
import Olle_vip_bimbel from '../routes/olle_vip_bimbel';

import Features from '../routes/features';
import Psikologi from '../routes/psikologi';
import Psikologi_detail from '../routes/psikologi_detail';
import Psikologi_detail_start from '../routes/psikologi_detail_start';
import Psikologi_detail_finish from '../routes/psikologi_detail_finish';

import Rmib from '../routes/rmib';
import Rmib_form from '../routes/rmib_form';
import Rmib_mulai_tes from '../routes/rmib_mulai_tes';
import Rmib_mulai_tes2 from '../routes/rmib_mulai_tes2';
import Rmib_hasil from '../routes/rmib_hasil';
import Rmib_kelompok from '../routes/rmib_kelompok';
import Rmib_kategori_jurusan from '../routes/rmib_kategori_jurusan';
import Rmib_target_jurusan from '../routes/rmib_target_jurusan';
import Rmib_target_jurusan_hubungi from '../routes/rmib_target_jurusan_hubungi';
import Rmib_target_jurusan_coming_soon from '../routes/rmib_target_jurusan_coming_soon';

import Register from '../routes/register';

import Article from '../routes/article';
import Article_detail from '../routes/article_detail';
// import Article_1 from '../routes/article_1';
// import Article_2 from '../routes/article_2';
import Tryout_pricelist from '../routes/tryout_pricelist';
import Tryout_register_individu from '../routes/tryout_cara_daftar_individu';
import Tryout_register_collective from '../routes/tryout_register_collective';
import Tryout_register_free from '../routes/tryout_register_free';
import Tryout_register_package from '../routes/tryout_register_package';
import Tryout_register_partner from '../routes/tryout_register_partner';
import Tryout_register_partner_sent from '../routes/tryout_register_partner_sent';
import Tryout_partner from '../routes/tryout_partner';
import Tryout_register_condition from '../routes/tryout_register_condition';
import Tryout_invoice from '../routes/tryout_invoice';
import Tryout_payment_success from '../routes/tryout_payment_success';
import Tryout_register_success from '../routes/tryout_register_success';
import Tryout_refund from '../routes/tryout_refund';


import Belajar_subject from '../routes/belajar_subject';
import Belajar_topic from '../routes/belajar_topic';
import Belajar_topic_detail from '../routes/belajar_topic_detail';
import Belajar_topic_detail_open from '../routes/belajar_topic_detail_open';


import Belajar_package from '../routes/belajar_package';
import Belajar_package_my from '../routes/belajar_package_my';
import Belajar_package_verification from '../routes/belajar_package_verification';
import Belajar_package_topic from '../routes/belajar_package_topic';
import Belajar_package_preview from '../routes/belajar_package_preview';
import Belajar_package_topic_sub from '../routes/belajar_package_topic_sub';
import Belajar_package_topic_sub_content from '../routes/belajar_package_topic_sub_content';
import Belajar_package_topic_sub_content_detail from '../routes/belajar_package_topic_sub_content_detail';

import Payment_preview from '../routes/payment_preview';
import Payment_checkout from '../routes/payment_checkout';
import Payment_history from '../routes/payment_history';

import Account from '../routes/account';
import Account_edit from '../routes/account_edit';
import Account_package_study from '../routes/account_package_study';
import Account_tryout from '../routes/account_tryout';
import Account_tryout_invite from '../routes/account_tryout_invite';
import Account_tryout_detail from '../routes/account_tryout_detail';
import Account_refund from '../routes/account_refund';

import Signup from '../routes/signup';
import Signup_verification from '../routes/signup_verification';

class Default extends Component {
	render(){
		return window.location.href='/'
	}
}
export default class App extends Component {
	
	/** Gets fired when the route changes.
	 *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
	 *	@param {string} event.url	The newly routed URL
	 */
	
	handleRoute = e => {
		const fbq_except_page_track = ['/paket-belajar/:slug']
		// Google Analytics
		ReactGA.pageview(window.location.pathname + window.location.search);
		
		// Facebook Pixels
		if(!e.current.props.path.includes(fbq_except_page_track)){
			ReactPixel.pageView(); // For tracking page view
		}
		// ReactPixel.track(event, data); // For tracking default events, more info about events and data https://developers.facebook.com/docs/ads-for-websites/pixel-events/v2.9
		// ReactPixel.trackSingle('PixelId', event, data); // For tracking default events, more info about events and data https://developers.facebook.com/docs/ads-for-websites/pixel-events/v2.9
		// ReactPixel.trackCustom(event, data); // For tracking custom events
		// ReactPixel.trackSingleCustom('PixelId', event, data); // For tracking custom events
		
	};

	render() {
		return (
			<div id="app">
				<Router onChange={this.handleRoute}>
					<Home path="/" />
					<Default default />
					<Features path="/features" />
					<Olle_vip path="/olle-vip" />
					<Olle_vip_alumni path="/olle-vip-alumni" />
					<Olle_vip_alumni_soshum path="/olle-vip-alumni-soshum" />
					<Olle_vip_register path="/olle-vip-daftar" />
					<Olle_vip_bimbel path="/olle-vip-bimbel" />
					<Promo path="/promo" />
					<Promo_detail_to10 path="/promo/to10" />
					<Promo_detail path="/promo/:slug" />
					<Promo_detail_register path="/promo/:slug/register" />
					<Program_intensif path="/program-intensif" />
					<Promo_detail_register_success path="/promo/:slug/register-success" />
					<Pman path="/pman" />
					<Pman_detil path="/pman/:jurusan/:id_transaction" />
					<Careers path="/careers" />
					<Psikologi path="/tes-psikologi" />
					<Psikologi_detail path="/tes-psikologi/:slug" />
					<Psikologi_detail_start path="/tes-psikologi/:slug/mulai" />
					<Psikologi_detail_finish path="/tes-psikologi/:slug/selesai" />

					<Rmib path="/rmib/:id_rmib" />
					<Rmib_form path="/rmib-form/:id_rmib" />
					<Rmib_mulai_tes path="/rmib-mulai-tes/:id_rmib/:kelompok" />
					<Rmib_mulai_tes2 path="/rmib-mulai-tes2/:id_rmib" />
					<Rmib_hasil path="/rmib-hasil/:id_rmib" />
					<Rmib_kelompok path="/rmib-kelompok/:id_rmib/:kelompok" />
					<Rmib_kategori_jurusan path="/rmib-kategori-jurusan/:id_rmib/:kategori" />
					<Rmib_target_jurusan path="/rmib-target-jurusan" />
					<Rmib_target_jurusan_hubungi path="/rmib-target-jurusan-hubungi" />
					<Rmib_target_jurusan_coming_soon path="/rmib-target-jurusan-coming-soon" />

					<Register path="/tryout/daftar" />
					<Article path="/artikel" />
					<Article_detail path="/artikel/:slug" />
					{/* <Article_1 path="/artikel/3-cara-agar-tidak-menyesal-saat-pilih-jurusan-kuliah" />
					<Article_2 path="/artikel/3-kesalahan-yang-sering-terjadi-saat-ujian-sbmptn" /> */}
					<Tryout_pricelist path="/tryout/pricelist" />
					<Tryout_register_individu path="/tryout/cara-daftar-olle-individu" />
					<Tryout_register_collective path="/tryout/daftar-paket-kolektif" />
					<Tryout_register_free path="/tryout/daftar-paket-gratis" />
					<Tryout_register_package path="/tryout/daftar-paket-to/:id/:type" />
					<Tryout_register_partner path="/tryout/daftar-mitra-olle" />
					<Tryout_register_partner_sent path="/tryout/daftar-mitra-olle-sent" />
					<Tryout_partner path="/tryout/mitra-olle" />
					<Tryout_register_condition path="/tryout/syarat-pendaftaran" />
					<Tryout_invoice path="/tryout/invoice" />
					<Tryout_payment_success path="/tryout/payment-success" />
					<Tryout_register_success path="/tryout/register-success" />
					<Tryout_refund path="/tryout/ajukan-refund" />
					<Belajar_subject path="/belajar/:grade_id" />
					<Belajar_topic path="/belajar/:grade_id/:subject_slug" />
					<Belajar_topic_detail path="/belajar/:grade_id/:subject_slug/:topic_id/:topic_url" />
					<Belajar_topic_detail_open path="/belajar/:grade_id/:subject_slug/:topic_id/:topic_url/:study_id" />

					<Belajar_package_verification path="/paket-belajar/verifikasi-ponsel-code/:slug" />
					<Belajar_package path="/paket-belajar" />
					<Belajar_package_my path="/paket-belajar-saya" />
					<Belajar_package_topic path="/paket-belajar/:slug" />
					<Belajar_package_preview path="/paket-belajar/:slug/preview/:id_transaction" />
					<Belajar_package_topic_sub path="/paket-belajar/:slug/:slug_topic" />
					<Belajar_package_topic_sub_content path="/paket-belajar/:slug/:slug_topic/:slug_topic_sub" />
					<Belajar_package_topic_sub_content_detail path="/paket-belajar/:slug/:slug_topic/:slug_topic_sub/:slug_topic_sub_content" />

					<Payment_preview path="/payment-preview/:id_tryout" />
					<Payment_checkout path="/payment-checkout/:id_tryout" />
					<Payment_history path="/payment-history" />

					<Account path="/akun-saya" />
					<Account_edit path="/akun-saya-edit" />
					<Account_package_study path="/paket-belajar-saya" />
					<Account_tryout path="/tryout-saya" />
					<Account_tryout_detail path="/tryout-saya/:slug" />
					<Account_tryout_invite path="/tryout-saya-invite/:parent_id/:id_transaction" />
					<Account_refund path="/ajukan-refund" />
					<Signup path="/signup" />
					<Signup_verification path="/signup-verification" />
				</Router>
			</div>
		);
	}
}
